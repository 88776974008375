<template>
  <base-page-item>
    <v-timeline-item
      left
      small
      class="task-item pa-3"
      :color="active ? 'blue lighten-2' : 'grey lighten-2'"
      :icon-color="active ? 'blue lighten-2' : 'grey lighten-2'"
    >
      <template v-slot:opposite>
        <div>
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Name: </span>{{ call.data.person.name }}
          </div>
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">City: </span>{{ call.data.person.city }}
          </div>
          <div class="text-left mb-0">
            <span
              v-if="call.data.person.email"
              class="font-weight-bold mr-1"
            >Email: </span>{{ call.data.person.email }}
          </div>
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Email status: </span>
            <span :class="[color]">{{
              call.data.person.email_status
            }}</span>
          </div>
        </div>
      </template>
      <v-row
        align="center"
        class="spacer"
        no-gutters
        justify="center"
      >
        <v-col cols="12">
          <div class="text-left mb-0">
            <span
              class="font-weight-bold mr-1"
            >Created_at: <br>
              {{ call.created_at }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>
  </base-page-item>
</template>

<script>
  import BasePageItem from '@/components/base/BasePageItem';

  export default {
    name: 'ApolloCallItem',
    components: {
      BasePageItem,
    },
    props: {
      call: {
        type: Object,
        default: () => {},
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      color () {
        let colors = '';

        switch (this.call.data.person.email_status) {
        case 'verified':
          colors = 'green--text   text--lighten-1';
          break;
        case 'extrapolated':
          colors = 'deep-purple--text  text--lighten-1';
          break;
        case 'unavailable':
          colors = 'red--text text--lighten-1';
          break;
        default:
          colors = 'red--text  text--lighten-1';
          break;
        }

        return colors;
      },
    },
  };
</script>

<style scoped lang="scss">
.task-item {
  transition-duration: 0.5s;
  cursor: pointer;

  &:hover {
    background: rgba(black, 0.03);
  }
}
</style>
